<template lang="">
  <div class="flex justify-center items-center relative" v-click-outside="closeDropdown">
    <img :src="iconType" class="relative z-10 block overflow-hidden  cursor-pointer smcom-ripple select-none" :class="styleType" @dblclick="isOpen = !isOpen" />

  <button v-if="isOpen" @click="isOpen = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-black opacity-0 cursor-default"></button>
    <div v-if="isOpen" class="absolute z-50 mt-2 shadow-xl min-w-max px-5" :class="positionStyle">
      <div class="popup-device-status">
        <div class="popup-content">
          <div class="detail-group">
            <div class="device-detail">
              <div class="device-detail-item">
                <span class="location" style="max-width:150px;overflow-x:hidden;white-space:nowrap;text-overflow:ellipsis;">{{deviceInfo.siteName}} &gt; {{deviceInfo.mapName}}</span>
                <div class="name">
                  {{deviceInfo.deviceName}}
                  <span class="flag-item round" :class="flagColor">{{deviceStatusList[deviceInfo.deviceStatus]}}</span>
                </div>
              </div>
              <dl class="date">
                <dt>최근신호수신</dt>
                <dd>{{deviceInfo.aliveDate | moment('YYYY-MM-DD HH:mm:ss') }}</dd>
              </dl>
            </div>
            <span class="device-id">innertron-{{deviceInfo.deviceID}}</span>
          </div>
          <div class="status-group">
            <dl class="status-item">
              <dt>탐지 카메라</dt>
              <dd>{{deviceInfo.detectCount}}</dd>
            </dl>
            <dl class="status-item">
              <dt>차단 카메라</dt>
              <dd>{{deviceInfo.blockCount}}</dd>
            </dl>
            <dl class="status-item">
              <dt>이벤트 알림</dt>
              <dd>{{deviceInfo.eventCount}}</dd>
            </dl>
          </div>
        </div>
        <div class="popup-footer">
          <button type="button" class="btn-footer" @click="moveDeviceDetail(deviceInfo.deviceGuid)">상세보기</button>
        </div>
        <button type="button" class="btn-close" @click="closeDropdown()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-black"></i>
        </button>
      </div>

    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ClickOutside from 'vue-click-outside'

import { requestApi } from "@/utils/api"
import { deviceStatusList } from "@/references/config"

export default {
  props: {
    deviceGuid: { type: String },
    pointX: { type: String },
    pointY: { type: String },
    mapImgWidth: { type: Number },
    mapImgHeight: { type: Number },
    index: { type: Number },
    deviceStatus: { type: String },
    detectCount: { type: Number }
  },
  directives: {
    ClickOutside
  },
  data () {
    return {
      deviceStatusList,
      isOpen: false,
      offlineIcon: require("@/assets/images/icon/ic-list-offline-signal.png"),
      loadingIcon: require("@/assets/images/icon/ic-list-loading-signal.png"),
      detectIcon: require("@/assets/images/icon/ic-list-catch-signal.png"),

      position: '',
      positionStyle: "top-1 left-1",
      iconType: null,
      deviceInfo: {},
      flagColor: 'gray'
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser','getUserRole']),
  },
  created () {
    this.setDeviceStyle()
  },
  methods: {
    closeDropdown () {
      this.isOpen = false
    },
    setDeviceStyle () {
      // if(this.detectCount > 0) {
      //   this.iconType = this.detectIcon
      //   this.styleType = 'smcom-detect'
      // }
      // else {
      //   if(this.deviceStatus === 'regist') {
      //     this.iconType = this.loadingIcon
      //     this.styleType = 'smcom-loading'
      //   }
      //   else {
      //     this.iconType = this.offlineIcon
      //     this.styleType = 'smcom-offline'
      //   }
      // }
      if(this.deviceStatus === 'regist') {
        this.iconType = this.loadingIcon
        this.styleType = 'smcom-loading'

        if(this.detectCount > 0) {
          this.iconType = this.detectIcon
          this.styleType = 'smcom-detect'
        }
      }
      else {
        this.iconType = this.offlineIcon
        this.styleType = 'smcom-offline'
      }
    },
    async getDeviceInfo () {
      let reqObj = {}
      reqObj.target = `/device/info/${this.deviceGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.deviceInfo = result.msg.retData
        if(result.msg.retData.deviceStatus === 'regist') this.flagColor = 'green'
      }
    },

    moveDeviceDetail (guid) {
      this.$router.push({name:'DefaultDevice',query:{guid}})
    }

  },
  watch: {
    'isOpen': function () {
      if(this.isOpen) {
        console.log("check regist", this.deviceGuid, this.mapImgWidth, this.mapImgHeight, this.pointX.slice(0,-2), this.pointY.slice(0,-2))
        let positionStr = ""
        if(parseInt(this.mapImgHeight / 2) > parseInt(this.pointY.slice(0,-2)) ) positionStr += "top-event"
        else positionStr += "bottom-event"

        if(parseInt(this.mapImgWidth / 2) > parseInt(this.pointX.slice(0,-2)) ) positionStr += " left-event"
        else positionStr += " right-event"

        this.positionStyle = positionStr

        console.log(this.mapImgHeight, this.pointY.slice(0,-2))
        console.log(this.mapImgWidth, this.pointX.slice(0,-2))
        console.log(positionStr)

        if(this.oldDeviceGuid!=='') {
          this.getDeviceInfo()
        }
      }
    },
    'deviceStatus': function () {
      this.setDeviceStyle()
    },
    'detectCount': function () {
      this.setDeviceStyle()
    }
  }
}
</script>
<style lang="">
</style>